.error{
    font-size: 12px;
    color: #ef2b2b;
}

.label{
    margin-top: 15px;
}

.input{
    margin-top: 10px;
}

.headline{
    color: #707070;
}

.headlineWrp{
    padding-bottom: 20px;
    border-bottom: 1px solid #F3F4F4;
}

.icon{
    margin-right: 10px;
    color: #000;
}

.btnWrp{
    margin-top: 40px;
}

.datePicker{

}

.serverError{
    margin: 15px 0 5px;
    font-weight: bold;
    /*font-size: 18px;*/
    color: #f55252;
}

.timePickerWrp{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

locationIcon{
    color: #fff;
}
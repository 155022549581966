.Logo{
    width: 100%;
    height: 100%;
    object-fit: contain;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 2;
}

.LogoInnerWrp{
    position: absolute;

    width: 100%;
    padding-top: 80%;

    z-index: 4;
}

.LogoWrp{
    position: relative;
    width: 160px;
    margin-right: 0px;
}

.navItemsOuterWrp,
.cartWrp{
    padding-top: 27.5px;
    padding-bottom: 27.5px;
}

.cartFixedWrp{
    position: fixed;
    top: 10px;
    right: 35px;

    width: 60px;
    height: 60px;

    background: white;
    border-radius: 50%;

    z-index: 2;

    -webkit-box-shadow: 10px 10px 42px -14px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 42px -14px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 42px -14px rgba(0,0,0,0.75);

    cursor: pointer;

    transition: opacity 0.3s ease;
}

.cartFixedWrp:hover{
    opacity: 0.7;
}


.cartFixedInnerWrp{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.navItemsInnerWrp{
    display: flex;
    align-items: center;
    margin: 0;
}

.navItem{
    list-style-type: none;
    margin-right: 18px;
    color: #000000;
    line-height: normal;
}

.cartIcon, .locationIcon{
    margin-right: 10px;
    color: #707070;
    font-size: 25px;
    cursor: pointer;
}

.cartPrice{
    color: #707070;
    position: relative;
    z-index: 3;
}

.cartIconWrp{
    transition: opacity .2s ease;
    cursor: pointer;
}

.cartIconWrp:hover, .locationIcon:hover{
    opacity: 0.6;
}

.cartAmount{
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    top: -10px;
    right: 2px;

    background: #000;
    color: white;
}

.mainWrp{
    padding-top: 85px;
}

.desktopNavbar{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;

    background: white;
    box-shadow: 0 0 15px -5px #000;

    z-index: 4;
}

.langToggle{
    margin-right: 20px;
    width: 30px;
    height: 30px;
    background-image: url(https://upload.wikimedia.org/wikipedia/commons/f/f2/Flag_of_Great_Britain_%281707%E2%80%931800%29.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.LanguageDropdownToggle{
    border: 1px solid black;
    border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
}

.locationMessageWrp{
    position: absolute;
    top: 11px;
    right: 0;

    width: 200px;
    border-radius: 10px;
}

.locationMessageArrow{
    position: absolute;
    top: 0;
    right: 12px;
    font-size: 27px;
    color: #707070;
    z-index: 2;
}

.locationMessage{
    padding: 10px;
    border-radius: 10px;
    background: #707070;
    color: white;
}

@media (max-width: 1100px) {
    .desktopNavbar{
        display: none;
    }

    .mainWrp{
        display: none;
    }
}

.wrp{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 999;
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
}

.modalWrp{
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    padding: 20px;

    z-index: 2;

    display: flex;
    justify-content: center;
}

.alert{
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 20px;
    background: #707070;
    color: white;
}

.closeBtn{
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #707070;

    top: 0;
    right: 0;

    transform: translate(40%, -40%);

    border: 1px solid black;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    transition: all .3s ease;
}

.closeBtn:hover{
    background: #404040;
}

@media (min-width: 1100px) {
    .modalWrp{
        max-width: 550px;
    }
}
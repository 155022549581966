/*wrp sets sizing and overflow props*/
.wrp{
    width: 100%;
    padding-top: 90%;
    position: relative;
    overflow: hidden;
}
/**/

/*INNER*/
.inner{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
}

.inner::before{
    content: "";
    display: block;

    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.35);
}
/**/

/*IMAGE*/
.img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;

    transition: all .3s ease;
}

.inner:hover .img{
    transform: scale(1.08);
}
/**/

/*category title*/
.textWrp{
    width: 100%;
    padding: 0 8px;
}

.title{
    position: relative;
    color: white;
    text-align: center;
    z-index: 1;

    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}
/**/

/*this query is for when there are two columns of categories*/
@media(max-width: 992px){
    /* even */
    .column:nth-child(2n){
        padding-left: 5px;
    }

    /* odd */
    .column:nth-child(2n-1){
        padding-right: 5px;
    }

}
/**/


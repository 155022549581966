.wrp{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 999;
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
}

.modalScrollWrp{
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
}

.modal{
    position: relative;
    display: block;
    padding: 20px;
    height: 100%;
}

.mapOuterWrp > div{
    flex-grow: 2;
}
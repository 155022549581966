.wrp{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
}

.wrp, .darkBg{
    width: 100%;
    height: 100%;
}

.darkBg{
    background: rgba(0,0,0,0.5);
    position: absolute;
}

.box{
    width: 80%;
    max-width: 500px;
    background: white;

    position: absolute;
    left: 50%;
    top: 50%;

    padding: 30px;
}

.title{
    color: #707070;
}

.par{
    color: #707070;
}

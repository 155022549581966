/*geo fonts*/
@font-face {
  font-family: geoBold;
  src: url(assets/fonts/bpg_nino_mtavruli_bold.ttf);
}

@font-face {
  font-family: geoReg;
  src: url(assets/fonts/bpg_arial_2009.ttf);
}

/*eng fonts*/
@font-face {
  font-family: engBold;
  src: url(assets/fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: engReg;
  src: url(assets/fonts/Roboto-Regular.ttf);
}

/*rus fonts*/
@font-face {
  font-family: rusBold;
  src: url(assets/fonts/Intro-Bold.ttf);
}

@font-face {
  font-family: rusReg;
  src: url(assets/fonts/RobotoCondensed-Light.ttf);
}

.btn-border-rounded{
  border-radius: 30px;
}

button:focus{
  outline: none;
}



html{
  font-size: 14px;
  overflow-y: auto;
}

html,body{
  height: 100%;
}

body{
  padding-top: 71px;
  font-family: bpg-arial;
}

@media(min-width: 1100px){
  body{
    padding-top: 0;
  }
}

@media(min-width: 576px){
  body{
    padding-left: 30px;
    padding-right: 30px;
  }
}

a{
  color: inherit;
}

a:hover{
  color: inherit;
  text-decoration: none;
}



h1,h2,h3,h4,h5,h6,p{
  margin-bottom: 0;
}

.h1, h1{
  font-size: 2rem;
}

.h2,h2{
  font-size: 1.8rem;
}

.h3,h3{
  font-size: 1.5rem;
}

@media(min-width: 1200px){
  .h3,h3{
    font-size: 2rem;
  }

  .h2, h2{
    font-size: 2.5rem;
  }

  .h1, h1{
    font-size: 2.7rem;
  }
}

/*noUiSlider*/
.noUi-handle{
  border: 6px solid #000;
  border-radius: 50%;

  background: white;
}

.noUi-horizontal .noUi-handle{
  width: 30px;
  height: 30px;
}

.noUi-handle:before, .noUi-handle:after{
  display: none;
}

.noUi-connect{
  background: #000;
}

.noUi-horizontal{
 height: 12px;
}

.noUi-horizontal .noUi-handle{
 top: -9px;
}

label{
  margin-bottom: 0;
}

.noUi-connects{
  cursor: pointer;
}

.react-datepicker-wrapper{
  width: 100%;
}

.border-none{
  border: none !important;
}

/*SWIPER*/
.swiper-button-next, .swiper-button-prev{
  color: black;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  margin: 0;
}

.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 20px;
}

.dropdown-item{
  cursor: pointer;
}


/* additional padding classes */
.py-2point5{
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}
/* /. */

/* additional border classes */
.border-none{
  border: none !important;
}
/* /. */

.main-layout-wrp{
  height: 100%;
  padding-top: 100px;
}

@media(max-width: 992px){
  .main-layout-wrp{
    padding-top: 20px;
  }
}

button.box-shadow{
  box-shadow: 0px 0px 20px -4px rgb(0 0 0 / 20%);
}

.line-through{
  text-decoration: line-through;
}

@keyframes fadInOut {
  0% { 
    opacity: 0; 
  }

  50% { 
    opacity: 100%; 
  }

  100% { 
    opacity: 0; 
  }
}